// @flow
import React from 'react';
import { ToolbarDelimitr } from '@graphite/uneon';
import type { TWidgetDiff, TDesign } from '@graphite/types';
import { defaultDesignBreakpointText, defaultDesign } from '@graphite/constants';
import { Design as SelectDesign } from '@graphite/selects';
import DesignColor from '@graphite/design-color-text';
import DesignStyle from '@graphite/design-style-text';
import { useTranslation } from 'react-i18next';
import { genId } from 'libs/firebase';

import { updateSpecs } from 'Editor/ducks/specs';
import ToolbarLayoutButton from 'Editor/Buttons/Toolbar/Layout';
import { editWidget } from 'Editor/ducks/widgets';
import DesignPanel from '@graphite/design-panel';

import { getSelectedBlocksType, toggleBlocksType } from '../libs/draft-utils';
import type { TConnectPropsWithControls } from '../constants/types';
import InlineStyles from './InlineStyles';
import TextAlign from './TextAlign';
import Colors from './Colors';
import LinkControl from './Link';
import Settings from './Settings';

const Controls = ({
	editorState,
	setEditorState,
	gridspec,
	colorspec,
	widgetspec,
	effectspec,
	dispatch,
	data,
	instanceId,
	originId,
	position,
	currentRef,
	currentDevice,
	insertImage,
	resetImage,
	removeImage,
	images,
	uploads,
}: TConnectPropsWithControls) => {
	const { t } = useTranslation();

	const { _id, designs } = data;

	const [openedPanel, setOpenedPanel] = React.useState(null);

	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, instanceId, originId, dispatch],
	);

	const defaultDesignText = React.useMemo(
		() => ({
			...defaultDesign,
			breakpoints: {
				[`${currentDevice}`]: defaultDesignBreakpointText,
			},
		}),
		[currentDevice],
	);

	const designId = React.useMemo(
		() => getSelectedBlocksType(editorState) || 'unstyled',
		[editorState],
	);

	const specDesign: ?TDesign = React.useMemo(
		() =>
			(designId && widgetspec.text.find(t => t._id === designId && !t.removedAt)) ||
			null,
		[designId, widgetspec.text],
	);
	const customDesign = (!specDesign && designs && designs[designId]) || null;
	const baseDesign: TDesign = React.useMemo(
		() => customDesign || specDesign || defaultDesignText,
		[customDesign, specDesign, defaultDesignText],
	);

	const setBlockType = React.useMemo(() => toggleBlocksType(editorState), [
		editorState,
	]);

	const saveDesign = React.useCallback(
		(nextId, design) => {
			if (!nextId && !design) {
				return;
			}
			if (!originId) return;
			if (design) {
				const diff = {
					designs: {
						...designs,
						[design._id]: design,
					},
				};
				dispatch(editWidget(_id, instanceId, originId, diff));
			}
			if (nextId && nextId !== designId) {
				setEditorState(setBlockType(nextId));
			}
		},
		[
			_id,
			designId,
			designs,
			dispatch,
			instanceId,
			originId,
			setBlockType,
			setEditorState,
		],
	);

	const updateSpecsHandler = React.useCallback(
		specs => {
			dispatch(updateSpecs(specs));
		},
		[dispatch],
	);

	return (
		<>
			<SelectDesign
				target="text"
				designId={designId}
				device={currentDevice}
				customDesign={customDesign}
				defaultDesign={baseDesign}
				DesignPanel={DesignPanel}
				DesignColor={DesignColor}
				DesignStyle={DesignStyle}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				onChange={saveDesign}
				t={t}
				genCustomId={genId}
				updateSpecs={updateSpecsHandler}
				insertImage={insertImage}
				removeImage={removeImage}
				resetImage={resetImage}
				images={images}
				uploads={uploads}
			/>
			<ToolbarDelimitr />

			<Colors
				device={currentDevice}
				design={baseDesign}
				editorState={editorState}
				setEditorState={setEditorState}
				colorspec={colorspec}
				setOpenedPanel={setOpenedPanel}
				openedPanel={openedPanel}
				genId={genId}
				gridspec={gridspec}
			/>

			<InlineStyles
				editorState={editorState}
				setEditorState={setEditorState}
				setOpenedPanel={setOpenedPanel}
				openedPanel={openedPanel}
			/>

			<TextAlign
				editorState={editorState}
				setEditorState={setEditorState}
				setOpenedPanel={setOpenedPanel}
				openedPanel={openedPanel}
			/>

			<LinkControl
				editorState={editorState}
				setEditorState={setEditorState}
				setOpenedPanel={setOpenedPanel}
				openedPanel={openedPanel}
				colorspec={colorspec}
				gridspec={gridspec}
				genId={genId}
			/>

			<Settings
				editorState={editorState}
				setEditorState={setEditorState}
				setOpenedPanel={setOpenedPanel}
				openedPanel={openedPanel}
			/>
			<ToolbarDelimitr />
			<ToolbarLayoutButton
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				save={saveDiff}
				data={data}
				position={position}
				currentRef={currentRef}
				unit={gridspec.unit}
				currentDevice={currentDevice}
			/>
		</>
	);
};

export default React.memo<TConnectPropsWithControls>(Controls);
