// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import type { Dispatch } from 'redux';
import { transitions } from '@graphite/constants';
import { PopupMenu, Toolbar, Flex } from '@graphite/uneon';
import useHotkeys from 'Editor/libs/use-hotkeys';
import useDefaultDevice from 'Editor/libs/use-default-device';
import { closestDeviceWithKey } from '@graphite/selectors';
import type {
	TId,
	TAction,
	TWidget,
	TPositionValue,
	TGridBreakpointName,
	TOffsetDevice,
} from '@graphite/types';
import {
	symbolizeWidget,
	detachWidget,
	removeWidget,
	cloneWidget,
	editWidget,
	unhideChildrenWidget,
} from 'Editor/ducks/widgets';
import { resetEdit } from 'Editor/ducks/editor';
import { getRect } from 'Editor/libs/use-rect';

type TProps = $ReadOnly<{|
	isActive: boolean,
	children: React$Node,
	data: TWidget,
	position: TPositionValue,
	originId: ?TId,
	instanceId: ?TId,
	containerId: ?TId,
	dispatch: Dispatch<TAction>,
	currentDevice: TGridBreakpointName,
	currentRef: {| current: ?HTMLDivElement |},
	repositionWidget: (
		targetId: TId,
		originId: TId,
		containerId: ?TId,
		position: TPositionValue,
		offset: TOffsetDevice,
	) => void,
|}>;

const flexSx = {
	justifyContent: 'center',
	flexGrow: '0',
	flexShrink: '0',
	flexWrap: 'nowrap',
	marginTop: '-72px',
	padding: '12px 18px 24px',
};

const hiddenSx = {
	...flexSx,
	opacity: 0,
	pointerEvents: 'none',

	transitionDuration: transitions.widgetControls.hideDuration,
	transitionTimingFunction: transitions.widgetControls.hideTiming,
	transitionDelay: transitions.widgetControls.hideDelay,
	transitionProperty: 'opacity',
};

const visibleSx = {
	...flexSx,
	opacity: 1,
	pointerEvents: 'auto',

	transitionDuration: transitions.widgetControls.showDuration,
	transitionTimingFunction: transitions.widgetControls.showTiming,
	transitionDelay: transitions.widgetControls.showDelay,
	transitionProperty: 'opacity',
};

const actionsButton = {
	variant: 'flat',
	colors: 'primaryflat',
	icons: 'dots-horizontal-3',
	size: 'md',
	title: 'Actions',
};

const ControlsPopup = (props: TProps) => {
	const {
		isActive,
		children,
		data,
		position,
		originId,
		instanceId,
		containerId,
		dispatch,
		currentRef,
		currentDevice,
		repositionWidget,
	} = props;
	const { t } = useTranslation();

	// ToDo useMemo
	// const isInstance = _.isArray(data.modified);
	const isDefaultDevice = currentDevice === useDefaultDevice();

	const { scope, scopeId, _id: targetId } = data;

	const symbolize = React.useCallback(() => {
		if (!scopeId) return;
		if (!originId) return;

		dispatch(
			symbolizeWidget(targetId, originId, {
				scope,
				_id: scopeId,
			}),
		);
	}, [dispatch, scope, targetId, originId, scopeId]);

	const detach = React.useCallback(() => {
		if (!originId) return;
		dispatch(detachWidget(targetId, instanceId, originId));
	}, [dispatch, targetId, originId, instanceId]);

	const remove = React.useCallback(() => {
		if (!originId) return;
		dispatch(removeWidget(targetId, containerId, instanceId, originId));
	}, [dispatch, targetId, containerId, instanceId, originId]);

	const hide = React.useCallback(() => {
		if (!originId) return;
		const box = closestDeviceWithKey(data.box, {
			currentDevice,
			key: `box-${targetId}`,
		});
		dispatch(
			editWidget(targetId, instanceId, originId, {
				box: _.set(currentDevice, _.set('hidden', true, box), data.box),
			}),
		);
	}, [originId, data.box, currentDevice, targetId, dispatch, instanceId]);

	const unhide = React.useCallback(() => {
		if (!originId) return;
		dispatch(unhideChildrenWidget(targetId, instanceId, originId));
	}, [dispatch, targetId, instanceId, originId]);

	const clone = React.useCallback(() => {
		if (!originId) return;
		dispatch(cloneWidget(targetId, containerId, instanceId, originId));
	}, [dispatch, targetId, containerId, instanceId, originId]);

	const setAbsolute = React.useCallback(() => {
		if (!originId) return;
		const rect = {
			...getRect(currentRef.current),
			left: currentRef.current?.offsetLeft ?? 0,
			top: currentRef.current?.offsetTop ?? 0,
		};
		repositionWidget(targetId, originId, containerId, 'absolute', rect);
	}, [originId, currentRef, repositionWidget, targetId, containerId]);

	const setStatic = React.useCallback(() => {
		if (!originId) return;
		repositionWidget(targetId, originId, containerId, null, {});
	}, [originId, repositionWidget, targetId, containerId]);

	const menuItems = React.useMemo(() => {
		const items = [];

		if (!isDefaultDevice) {
			if (data.kind === 'stack') {
				items.push({
					label: t('Show Hidden Widgets'),
					name: 'unhide',
				});
			}

			items.push({
				label: t('Hide on Device'),
				name: 'hide',
			});

			return { colors: 'primaryflat', items };
		}

		// if (!isInstance && !instanceId)
		// 	items.push({
		// 		label: 'Create Component',
		// 		name: 'symbolize',
		// 	});

		// if (isInstance && instanceId !== undefined)
		// 	items.push({
		// 		label: 'Detach Component',
		// 		name: 'detach',
		// 	});

		if (!position)
			items.push({
				label: t('Move to Absolute'),
				name: 'setAbsolute',
			});

		if (position && position.includes('absolute'))
			items.push({
				label: t('Move to Grid'),
				name: 'setStatic',
			});

		if (data.kind === 'stack') {
			items.push({
				label: t('Show Hidden Widgets'),
				name: 'unhide',
			});
		}

		items.push({
			label: t('Hide on Device'),
			name: 'hide',
		});

		items.push({
			label: t('Duplicate'),
			name: 'clone',
		});

		items.push({
			label: t('Remove'),
			name: 'remove',
		});

		return { colors: 'primaryflat', items };
	}, [data.kind, isDefaultDevice, position, t]);

	const handleSelectAction = React.useCallback(
		(e, action) => {
			if (!action || typeof action !== 'string') return;

			({
				symbolize,
				detach,
				remove,
				hide,
				unhide,
				clone,
				setAbsolute,
				setStatic,
			}[action]());
		},
		[symbolize, detach, remove, hide, unhide, clone, setAbsolute, setStatic],
	);

	useHotkeys(['Control+d', 'Meta+d'], e => {
		e.preventDefault();
		if (isActive) clone();
	});

	useHotkeys(['Control+h', 'Meta+Shift+h'], e => {
		e.preventDefault();
		if (isActive) hide();
	});

	useHotkeys(['delete', 'backspace'], e => {
		e.preventDefault();
		if (isActive) remove();
	});

	useHotkeys('Escape', e => {
		e.preventDefault();
		e.stopPropagation();
		if (isActive) dispatch(resetEdit());
	});

	return (
		isActive && (
			<Flex sx={isActive ? visibleSx : hiddenSx}>
				<Toolbar>
					{children}
					<PopupMenu
						button={actionsButton}
						list={menuItems}
						onClick={handleSelectAction}
					/>
				</Toolbar>
			</Flex>
		)
	);
};

export default React.memo<TProps>(ControlsPopup);
