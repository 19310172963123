// @flow
import _ from 'lodash/fp';

import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';
import {
	getCurrentPageId,
	getWidgetsByKind,
	getImageLibraryListUploads,
} from '@graphite/selectors';
import { matchPath } from 'react-router';
import emptyObject from 'empty/object';

import mapStateToPropsPublished from './mapStateToProps';
import type {
	TConnectProps,
	TPropsControl,
	TConnectPropsEditPre,
} from '../constants/types';

type TGetControlsArgs = {
	editor: $PropertyType<TStateEditor, 'editor'>,
	data: $PropertyType<TConnectProps, 'data'>,
};
type TGetControls = TGetControlsArgs => TPropsControl;

const getControls: TGetControls = _.cond([
	[
		// если редактируется ребёнок этой колонки
		({ editor, data }: TGetControlsArgs) => {
			return (
				editor &&
				editor.currentWidget &&
				editor.currentWidget.widgetChain &&
				editor.currentWidget.widgetChain.includes(data._id)
			);
		},
		// то показывае особые контролы
		_.constant<'baby'>('baby'),
	],
	[
		// если редактируется хоть что-то
		({ editor }: TGetControlsArgs) => editor?.currentWidget,
		// то показывае никакие контролы
		_.constant<'none'>('none'),
	],
	[
		// иначе показываем обычные контролы
		_.stubTrue,
		_.constant<'widget'>('widget'),
	],
]);

const mapStateToProps = (
	state: TStateEditor,
	ownProps: TWidgetOwnProps,
): TConnectPropsEditPre => {
	const { editor, router } = state;
	const currentPage = getCurrentPageId(state);
	const mapStateToPropsFromPublished = mapStateToPropsPublished(state, ownProps);
	const { data } = mapStateToPropsFromPublished;
	const { params: { siteId } = {} } =
		matchPath(router.location.pathname, {
			path: '/project/:projectId/site/:siteId',
		}) || emptyObject;

	return {
		...mapStateToPropsFromPublished,
		currentDevice: editor.currentDevice,
		controls: getControls({ editor, data }),
		widgetChain: ownProps?.widgetChain ?? [],
		originId: currentPage,
		images: getWidgetsByKind(state, {
			kind: 'file',
			scopeId: siteId,
			removedAt: null,
		}),
		uploads: getImageLibraryListUploads(state),
	};
};

export default mapStateToProps;
