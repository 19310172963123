// @flow

import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';
import { matchPath } from 'react-router';
import emptyObject from 'empty/object';
import { getWidgetsByKind, getImageLibraryListUploads } from '@graphite/selectors';
import getMapStateToPropsEdit from 'Widget/libs/primitive/getMapStateToPropsEdit';

const mapDispatchToPropsEdit = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const { params: { siteId } = {} } =
		matchPath(state.router.location.pathname, {
			path: '/project/:projectId/site/:siteId',
		}) || emptyObject;

	return {
		...getMapStateToPropsEdit(state, ownProps),
		images: getWidgetsByKind(state, {
			kind: 'file',
			scopeId: siteId,
			removedAt: null,
		}),
		uploads: getImageLibraryListUploads(state),
	};
};

export default mapDispatchToPropsEdit;
